// 引入Vue Router
import { createRouter, createWebHistory } from 'vue-router'


// 创建路由
const router = createRouter({
  mode: 'hash',
  history: createWebHistory(),
  routes: [
		{
			path: '/',
			name:'index',//主页
			component:()=> import('@/views/index.vue'),
			meta:{
				requiresAuth:false
			},
		}
		// { 
		// 	path: '/',
		// 	name:'HeadComponent',//主页
		// 	component:()=> import('@/components/HeadComponent.vue'),
		// 	meta:{
		// 		requiresAuth:false
		// 	},
		// 	children:[
		// 		{ 
		// 			path: '',
		// 			name:'indexComponent',
		// 			component:()=> import('@/views/icdia/index.vue'),
		// 			meta:{
		// 				requiresAuth:false
		// 			}
		// 		},
		// 		{
		// 			path:'/News',
		// 			name:'News',//新闻
		// 			component:()=> import('@/views/icdia/News.vue'),
		// 		},
		// 		{
		// 			path:'/NewsDetail',
		// 			name:'NewsDetail',//新闻
		// 			component:()=> import('@/views/icdia/NewsDetail.vue'),
		// 		},
		// 		{
		// 			path:'/Review',
		// 			name:'Review',//新闻
		// 			component:()=> import('@/views/icdia/Review.vue'),
		// 		},
		// 		{
		// 			path:'/ReviewDetail',
		// 			name:'ReviewDetail',//新闻
		// 			component:()=> import('@/views/icdia/ReviewDetail.vue'),
		// 		},
		// 		{
		// 			path:'/Agenda',
		// 			name:'Agenda',//新闻
		// 			component:()=> import('@/views/icdia/Agenda.vue'),
		// 		},
		// 		{
		// 			path:'/Meeting',
		// 			name:'Meeting',//新闻
		// 			component:()=> import('@/views/icdia/meeting/Meeting.vue'),
		// 			children:
		// 			[
		// 				{
		// 					path:"/Notice",
		// 					name:'Notice',//教学团队
		// 					component:()=> import('@/views/icdia/meeting/Notice.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Notice'
		// 					},
		// 				},
		// 				{
		// 					path:"/Highlights",
		// 					name:'Highlights',//师资概况
		// 					component:()=> import('@/views/icdia/meeting/Highlights.vue'),
		// 					meta: {
		// 						keepAlive: false, //需要被缓存的组件,
		// 						active:'Highlights'
		// 					},
		// 				},
		// 				{
		// 					path:"/Contemporaneous",
		// 					name:'Contemporaneous',//师资概况
		// 					component:()=> import('@/views/icdia/meeting/Contemporaneous.vue'),
		// 					meta: {
		// 						keepAlive: false, //需要被缓存的组件,
		// 						active:'Contemporaneous'
		// 					},
		// 				},
		// 				{
		// 					path:"/ContemporaneousDetail",
		// 					name:'ContemporaneousDetail',//师资概况
		// 					component:()=> import('@/views/icdia/meeting/ContemporaneousDetail.vue'),
		// 					meta: {
		// 						keepAlive: false, //需要被缓存的组件,
		// 						active:'ContemporaneousDetail'
		// 					},
		// 				},
		// 			]
		// 		},
		// 		{
		// 			path:'/Exhibitions',
		// 			name:'Exhibitions',//新闻
		// 			component:()=> import('@/views/icdia/exhibitions/Exhibitions.vue'),
		// 			children:
		// 			[
		// 				{
		// 					path:"/Registration",
		// 					name:'Registration',//师资概况
		// 					component:()=> import('@/views/icdia/exhibitions/RegisterAtion.vue'),
		// 					meta: {
		// 						keepAlive: true, //需要被缓存的组件,
		// 						active:'Registration'
		// 					},
		// 				},
		// 				{
		// 					path:"/Plan",
		// 					name:'Plan',//教学团队
		// 					component:()=> import('@/views/icdia/exhibitions/Plan.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Plan'
		// 					},
		// 				},
		// 				{
		// 					path:"/Handbook",
		// 					name:'Handbook',//教学团队
		// 					component:()=> import('@/views/icdia/exhibitions/Handbook.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Handbook'
		// 					},
		// 				},
		// 				{
		// 					path:"/Guide",
		// 					name:'Guide',//教学团队
		// 					component:()=> import('@/views/icdia/exhibitions/Guide.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Guide'
		// 					},
		// 				},
		// 				{
		// 					path:"/Information",
		// 					name:'Information',//教学团队
		// 					component:()=> import('@/views/icdia/exhibitions/Information.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Information'
		// 					},
		// 				},
		// 				{
		// 					path:"/Report",
		// 					name:'Report',//教学团队
		// 					component:()=> import('@/views/icdia/exhibitions/Report.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Report'
		// 					},
		// 				},
		// 				{
		// 					path:"/Hotel",
		// 					name:'Hotel',//教学团队
		// 					component:()=> import('@/views/icdia/exhibitions/Hotel.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Hotel'
		// 					},
		// 				},
		// 				{
		// 					path:"/Question",
		// 					name:'Question',//教学团队
		// 					component:()=> import('@/views/icdia/exhibitions/Question.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Question'
		// 					},
		// 				},
		// 				{
		// 					path:"/Link",
		// 					name:'Link',//教学团队
		// 					component:()=> import('@/views/icdia/exhibitions/Link.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Link'
		// 					},
		// 				},
		// 			]
		// 		},
		// 		{
		// 			path:'/Attend',
		// 			name:'Attend',//新闻
		// 			component:()=> import('@/views/icdia/Attend/Attend.vue'),
		// 			children:
		// 			[
		// 				{
		// 					path:"/Apply",
		// 					name:'Apply',//师资概况
		// 					component:()=> import('@/views/icdia/Attend/Apply.vue'),
		// 					meta: {
		// 						keepAlive: true, //需要被缓存的组件,
		// 						active:'Apply'
		// 					},
		// 				},
		// 				{
		// 					path:"/AttendGuide",
		// 					name:'AttendGuide',//教学团队
		// 					component:()=> import('@/views/icdia/Attend/AttendGuide.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'AttendGuide'
		// 					},
		// 				},
		// 				{
		// 					path:"/Businessman",
		// 					name:'Businessman',//教学团队
		// 					component:()=> import('@/views/icdia/Attend/Businessman.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Businessman'
		// 					},
		// 				},
		// 				{
		// 					path:"/AttendQuestion",
		// 					name:'AttendQuestion',//教学团队
		// 					component:()=> import('@/views/icdia/Attend/AttendQuestion.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'AttendQuestion'
		// 					},
		// 				},
		// 				{
		// 					path:"/AttendLink",
		// 					name:'AttendLink',//教学团队
		// 					component:()=> import('@/views/icdia/Attend/AttendLink.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'AttendLink'
		// 					},
		// 				},
		// 			]
		// 		}
		// 	]
		// },
		// {
		// 	path: '/',
		// 	name:'aeif',//主页
		// 	component:()=> import('@/components/AEIF.vue'),
		// 	meta:{
		// 		requiresAuth:false
		// 	},
		// 	children:[
		// 		{ 
		// 			path: '',
		// 			name:'aeifComponent',
		// 			component:()=> import('@/views/aeif/index.vue'),
		// 			meta:{
		// 				requiresAuth:false
		// 			}
		// 		},
		// 		{
		// 			path:'/aeif/Review',
		// 			name:'aeif/Review',//新闻
		// 			component:()=> import('@/views/aeif/Review.vue'),
		// 		},
		// 		{
		// 			path:'/aeif/Agenda',
		// 			name:'aeif/Agenda',//新闻
		// 			component:()=> import('@/views/aeif/Agenda.vue'),
		// 		},
		// 		{
		// 			path:'/aeif/Meeting',
		// 			name:'aeif/Meeting',//新闻
		// 			component:()=> import('@/views/aeif/meeting/Meeting.vue'),
		// 			children:
		// 			[
		// 				{
		// 					path:"/aeif/Notice",
		// 					name:'aeif/Notice',//教学团队
		// 					component:()=> import('@/views/aeif/meeting/Notice.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Notice'
		// 					},
		// 				},
		// 				{
		// 					path:"/aeif/Highlights",
		// 					name:'aeif/Highlights',//师资概况
		// 					component:()=> import('@/views/aeif/meeting/Highlights.vue'),
		// 					meta: {
		// 						keepAlive: false, //需要被缓存的组件,
		// 						active:'Highlights'
		// 					},
		// 				},
		// 			]
		// 		},
		// 		{
		// 			path:'/aeif/Exhibitions',
		// 			name:'aeif/Exhibitions',//新闻
		// 			component:()=> import('@/views/aeif/exhibitions/Exhibitions.vue'),
		// 			children:
		// 			[
		// 				{
		// 					path:"/aeif/Registration",
		// 					name:'aeif/Registration',//师资概况
		// 					component:()=> import('@/views/aeif/exhibitions/RegisterAtion.vue'),
		// 					meta: {
		// 						keepAlive: true, //需要被缓存的组件,
		// 						active:'Registration'
		// 					},
		// 				},
		// 				{
		// 					path:"/aeif/Plan",
		// 					name:'aeif/Plan',//教学团队
		// 					component:()=> import('@/views/aeif/exhibitions/Plan.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Plan'
		// 					},
		// 				},
		// 				{
		// 					path:"/aeif/Handbook",
		// 					name:'aeif/Handbook',//教学团队
		// 					component:()=> import('@/views/aeif/exhibitions/Handbook.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Handbook'
		// 					},
		// 				},
		// 				{
		// 					path:"/aeif/Guide",
		// 					name:'aeif/Guide',//教学团队
		// 					component:()=> import('@/views/aeif/exhibitions/Guide.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Guide'
		// 					},
		// 				},
		// 				{
		// 					path:"/aeif/Information",
		// 					name:'aeif/Information',//教学团队
		// 					component:()=> import('@/views/aeif/exhibitions/Information.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Information'
		// 					},
		// 				},
		// 				{
		// 					path:"/aeif/Report",
		// 					name:'aeif/Report',//教学团队
		// 					component:()=> import('@/views/aeif/exhibitions/Report.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Report'
		// 					},
		// 				},
		// 				{
		// 					path:"/aeif/Hotel",
		// 					name:'aeif/Hotel',//教学团队
		// 					component:()=> import('@/views/aeif/exhibitions/Hotel.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Hotel'
		// 					},
		// 				},
		// 				{
		// 					path:"/aeif/Question",
		// 					name:'aeif/Question',//教学团队
		// 					component:()=> import('@/views/aeif/exhibitions/Question.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Question'
		// 					},
		// 				},
		// 				{
		// 					path:"/aeif/Link",
		// 					name:'aeif/Link',//教学团队
		// 					component:()=> import('@/views/aeif/exhibitions/Link.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Link'
		// 					},
		// 				},
		// 			]
		// 		},
		// 		{
		// 			path:'/aeif/Attend',
		// 			name:'aeif/Attend',//新闻
		// 			component:()=> import('@/views/aeif/Attend/Attend.vue'),
		// 			children:
		// 			[
		// 				{
		// 					path:"/aeif/Apply",
		// 					name:'aeif/Apply',//师资概况
		// 					component:()=> import('@/views/aeif/Attend/Apply.vue'),
		// 					meta: {
		// 						keepAlive: true, //需要被缓存的组件,
		// 						active:'Apply'
		// 					},
		// 				},
		// 				{
		// 					path:"/aeif/AttendGuide",
		// 					name:'aeif/AttendGuide',//教学团队
		// 					component:()=> import('@/views/aeif/Attend/AttendGuide.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'AttendGuide'
		// 					},
		// 				},
		// 				{
		// 					path:"/aeif/Businessman",
		// 					name:'aeif/Businessman',//教学团队
		// 					component:()=> import('@/views/aeif/Attend/Businessman.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Businessman'
		// 					},
		// 				},
		// 				{
		// 					path:"/aeif/AttendQuestion",
		// 					name:'aeif/AttendQuestion',//教学团队
		// 					component:()=> import('@/views/aeif/Attend/AttendQuestion.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'AttendQuestion'
		// 					},
		// 				},
		// 				{
		// 					path:"/aeif/AttendLink",
		// 					name:'aeif/AttendLink',//教学团队
		// 					component:()=> import('@/views/aeif/Attend/AttendLink.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'AttendLink'
		// 					},
		// 				},
		// 			]
		// 		}
		// 	]
		// },
		// {
		// 	path: '/',
		// 	name:'iccad',//主页
		// 	component:()=> import('@/components/ICCAD.vue'),
		// 	meta:{
		// 		requiresAuth:false
		// 	},
		// 	children:[
		// 		{ 
		// 			path: '',
		// 			name:'ICCADComponent',
		// 			component:()=> import('@/views/iccad/index.vue'),
		// 			meta:{
		// 				requiresAuth:false
		// 			}
		// 		},
		// 		{
		// 			path:"/iccad/Notice",
		// 			name:'iccad/Notice',//教学团队
		// 			component:()=> import('@/views/iccad/Notice.vue'),
		// 			meta: {
		// 				keepAlive: false, //不需要被缓存的组件
		// 				active:'Notice'
		// 			},
		// 		},
		// 		{
		// 			path:"/iccad/Agenda",
		// 			name:'iccad/Agenda',//教学团队
		// 			component:()=> import('@/views/iccad/Agenda.vue'),
		// 			meta: {
		// 				keepAlive: false, //不需要被缓存的组件
		// 				active:'Agenda'
		// 			},
		// 		},
		// 		{
		// 			path:"/iccad/Review",
		// 			name:'iccad/Review',//教学团队
		// 			component:()=> import('@/views/iccad/Review.vue'),
		// 			meta: {
		// 				keepAlive: false, //不需要被缓存的组件
		// 				active:'Review'
		// 			},
		// 		},
		// 		{
		// 			path:"/iccad/ReviewDetail",
		// 			name:'iccad/ReviewDetail',//教学团队
		// 			component:()=> import('@/views/iccad/ReviewDetail.vue'),
		// 			meta: {
		// 				keepAlive: false, //不需要被缓存的组件
		// 				active:'Review'
		// 			},
		// 		},
		// 		{
		// 			path:"/iccad/Contact",
		// 			name:'iccad/Contact',//教学团队
		// 			component:()=> import('@/views/iccad/Contact.vue'),
		// 			meta: {
		// 				keepAlive: false, //不需要被缓存的组件
		// 				active:'Review'
		// 			},
		// 		},
		// 		{
		// 			path:'/iccad/Exhibitions',
		// 			name:'iccad/Exhibitions',//新闻
		// 			component:()=> import('@/views/iccad/exhibitions/Exhibitions.vue'),
		// 			children:
		// 			[
		// 				{
		// 					path:"/iccad/Registration",
		// 					name:'iccad/Registration',//师资概况
		// 					component:()=> import('@/views/iccad/exhibitions/RegisterAtion.vue'),
		// 					meta: {
		// 						keepAlive: true, //需要被缓存的组件,
		// 						active:'Registration'
		// 					},
		// 				},
		// 				{
		// 					path:"/iccad/Plan",
		// 					name:'iccad/Plan',//教学团队
		// 					component:()=> import('@/views/iccad/exhibitions/Plan.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Plan'
		// 					},
		// 				},
		// 				{
		// 					path:"/iccad/Business",
		// 					name:'iccad/Business',//教学团队
		// 					component:()=> import('@/views/iccad/exhibitions/Business.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Business'
		// 					},
		// 				},
		// 				{
		// 					path:"/iccad/BusinessMan",
		// 					name:'iccad/BusinessMan',//教学团队
		// 					component:()=> import('@/views/iccad/exhibitions/BusinessMan.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'BusinessMan'
		// 					},
		// 				},
		// 				{
		// 					path:"/iccad/Guide",
		// 					name:'iccad/Guide',//教学团队
		// 					component:()=> import('@/views/iccad/exhibitions/Guide.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Guide'
		// 					},
		// 				},
		// 				{
		// 					path:"/iccad/Information",
		// 					name:'iccad/Information',//教学团队
		// 					component:()=> import('@/views/iccad/exhibitions/Information.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Information'
		// 					},
		// 				},
		// 				{
		// 					path:"/iccad/Report",
		// 					name:'iccad/Report',//教学团队
		// 					component:()=> import('@/views/iccad/exhibitions/Report.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Report'
		// 					},
		// 				},
		// 				{
		// 					path:"/iccad/Hotel",
		// 					name:'iccad/Hotel',//教学团队
		// 					component:()=> import('@/views/iccad/exhibitions/Hotel.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Hotel'
		// 					},
		// 				},
		// 				{
		// 					path:"/iccad/Question",
		// 					name:'iccad/Question',//教学团队
		// 					component:()=> import('@/views/iccad/exhibitions/Question.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Question'
		// 					},
		// 				},
		// 				{
		// 					path:"/iccad/Link",
		// 					name:'iccad/Link',//教学团队
		// 					component:()=> import('@/views/iccad/exhibitions/Link.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'Link'
		// 					},
		// 				},
		// 			]
		// 		},
		// 		{
		// 			path:'/iccad/Attend',
		// 			name:'iccad/Attend',//新闻
		// 			component:()=> import('@/views/iccad/Attend/Attend.vue'),
		// 			children:
		// 			[
		// 				{
		// 					path:"/iccad/AttendGuide",
		// 					name:'iccad/AttendGuide',//教学团队
		// 					component:()=> import('@/views/iccad/Attend/AttendGuide.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'AttendGuide'
		// 					},
		// 				},
		// 				{
		// 					path:"/iccad/AttendLink",
		// 					name:'iccad/AttendLink',//教学团队
		// 					component:()=> import('@/views/iccad/Attend/AttendLink.vue'),
		// 					meta: {
		// 						keepAlive: false, //不需要被缓存的组件
		// 						active:'AttendLink'
		// 					},
		// 				},
		// 			]
		// 		}
		// 	]
		// },
	]
})

import Header from '@/components/HeadComponent.vue'
// 使用 router.beforeEach 注册一个全局前置守卫，用于拦截路由跳转
router.beforeEach((to, from, next) => {
	// var ua = navigator.userAgent.toLowerCase();
	// //var isWeixin = ua.indexOf('micromessenger') != -1;
	// var isAndroid = ua.indexOf('android') != -1;
	// var isApple = (ua.indexOf('iphone') != -1) || (ua.indexOf('ipad') != -1);
	// // 这里根据移动端浏览器的 navigator.userAgent 特性判断是否为移动端浏览
	// var isMoveClient =  isAndroid || isApple;
	// if (isMoveClient) {
	// 	next(false)
	// } else {
		next()
	// }
})




export default router