<template>
    <router-view></router-view>
</template>

<script>
import '@wangeditor/editor/dist/css/style.css';
export default {
	name: 'App',
	components: {},
}
</script>

<style>
	body{
		widht:100%;
		margin:0;
		font-family: '微软雅黑';
		font-style: normal;
		font-weight: normal;
		-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;
		-webkit-text-size-adjust: none;
	}
	p {
		margin: 0;
	}
	.arrow-link:hover {
		transform: translateX(50%);
	}
	.arrow-link {
		cursor: pointer;
		transition: transform .3s ease-in-out;
	}
	.border:after {
	
	  position: absolute; 
	
	  content: '';
	
	  width: 100%;
	
	  left: 0;
	
	  bottom: 0;
	
	  height: 1px;
	
	  background-color: #e3e5e9;
	
	  -webkit-transform: scale(1, 0.5);
	
	  transform: scale(1, 0.5);
	
	  -webkit-transform-origin: center bottom;
	
	  transform-origin: center bottom;
	
	}
	.border::before {
	    content: '';
	    position: absolute;
	    width: 200%;
	    height: 200%;
	    border: 1px solid #eee;
	    transform-origin: 0 0;
	    transform: scale(0.5, 0.5);  
	    -webkit-transform: scale(0.5, 0.5);
	    box-sizing: border-box;
	 }
</style>
