import { createApp } from 'vue'
import App from './App.vue'
import axios from './api/axios'
import router from '@/router/index'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import HeadComponent from '@/components/HeadComponent.vue'
import DevicePixelRatio from './utils/devicePixelRatio'
const app = createApp(App)

//注册全局组件
app.component('HeadComponent',HeadComponent)

// 使用路由
app.use(router)

//使用element-plus
app.use(ElementPlus,{locale:zhCn})


//挂载
app.mount('#app')
const Ratio = new DevicePixelRatio()
Ratio.init()

// 在 Vue 原型中添加 axiosInstance
app.config.globalProperties.$axios = axios



