/*
//引入localStorage
import localStorage from 'localStorage'
 if (typeof window.localStorage === 'undefined' || window.localStorage === null) {
  var LocalStorage = require('node-localstorage').LocalStorage
  localStorage = new LocalStorage('./scratch')
} */


// 判断浏览器是否支持 localStorage
const localStorage = window.localStorage || require('node-localstorage').LocalStorage

export default localStorage
